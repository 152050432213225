import React from 'react'
import { Layout } from './components/Layout'
import { HeaderSection } from './components/HeaderSection'
import { ContactUsSection } from './components/ContactUsSection'
import { FooterSection } from './components/FooterSection'

import PageHeader from './components/common/PageHeader';

const ContactUsContainer = () => {
  return (
    <Layout>
       <HeaderSection navDark/>

       <PageHeader
        title='Contact Us'
        desc='You May Contact Us Using The Information Below'
      />
      
      <ContactUsSection />

      <FooterSection
        style={{
          background:
            "url('assets/images/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
    
  )
}

export default ContactUsContainer
