import React from 'react'
import { Layout } from './components/Layout'
import { HeaderSection } from './components/HeaderSection'
import { CancellationAndRefundSection } from './components/CancellationAndRefundSection'
import { FooterSection } from './components/FooterSection'

import PageHeader from './components/common/PageHeader';

const CancellationAndRefundContainer = () => {
  return (
    <Layout>
       <HeaderSection navDark/>

       <PageHeader
        title='Cancellation & Refund Policy'
        desc='Last updated on Sep 29th 2023'
      />
      
      <CancellationAndRefundSection />

      <FooterSection
        style={{
          background:
            "url('assets/images/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
    
  )
}

export default CancellationAndRefundContainer
