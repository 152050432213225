import React from 'react';
import { Link } from 'react-router-dom';
import { SectionTitle } from './SectionTitle';

export const PriceSection = ({ paddingTop, haspriceTitleWithHeader }) => {
  return (
    <>
      <section
        id='pricing-section'
        className={`pricing-section ${paddingTop} position-relative z-2`}
      >
        <div className='container'>
          {haspriceTitleWithHeader ? (
            <div>
              <div className='row justify-content-center'>
                <div className='col-lg-6 col-md-10'>
                  <SectionTitle
                    subtitle='Pricing'
                    variant='text-primary'
                    title='Check Our Valuable Price'
                    description='Our plans are designed to fit the needs of most Schools Or Institutes. 
                    However, if you require a customized solution to meet your specific needs, Please request a personalized plan that is tailored just for you'
                    centerAlign
                  />
                </div>
              </div>
              <div className='row justify-content-center mb-5'>
                <div className='col-lg-3'>
                  <div className='media d-flex align-items-center py-2 p-sm-2'>
                    <div
                      className='
                    icon-box
                    mb-0
                    bg-primary-soft
                    rounded-circle
                    d-block
                    me-3
                  '
                    >
                      <i className='fal fa-credit-card text-primary'></i>
                    </div>
                    <div className='media-body fw-medium h6 mb-0'>
                      No Upfront Charges
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='media d-flex align-items-center py-2 p-sm-2'>
                    <div
                      className='
                    icon-box
                    mb-0
                    bg-success-soft
                    rounded-circle
                    d-block
                    me-3
                  '
                    >
                      <i className='fal fa-calendar-check text-success'></i>
                    </div>
                    <div className='media-body fw-medium h6 mb-0'>
                    100% satisfaction guaranteed
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='media d-flex align-items-center py-2 p-sm-2'>
                    <div
                      className='
                    icon-box
                    mb-0
                    bg-danger-soft
                    rounded-circle
                    d-block
                    me-3
                  '
                    >
                      <i className='fal fa-calendar-times text-danger'></i>
                    </div>
                    <div className='media-body fw-medium h6 mb-0'>
                      Cancel anytime
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className='row'>
            <div className='col-lg-4 col-md-6 card-group'>
              <div
                className='card
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-white
                  custom-shadow
                  p-5
                  mb-4 mb-lg-0
                '
              >
                <div className='pricing-header mb-32'>
                  <h3 className='package-name text-primary d-block'>Starter</h3>
                  <h4 className='display-6 fw-semi-bold'>
                    &#8377; 10,000<span>/year</span>
                  </h4>
                </div>
                <div className='pricing-info mb-4'>
                  <ul className='pricing-feature-list list-unstyled'>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Student Management
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Invoicing
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Payment Reminder
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      SMS Notification
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Digital Payments
                    </li>
                  </ul>
                </div>
                <Link
                  to='/'
                  className='btn btn-outline-primary mt-auto width-fit-content'
                >
                  Buy Now
                </Link>

                {/* <!--pattern start--> */}
                <div
                  className='
                    dot-shape-bg
                    position-absolute
                    z--1
                    left--40
                    bottom--40
                  '
                >
                  <img src='assets/images/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-4 col-md-6 card-group'>
              <div
                className='card
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-gradient
                  text-white
                  p-5
                  mb-4 mb-lg-0
                '
              >
                <div className='pricing-header mb-32'>
                  <h3 className='package-name text-warning d-block'>
                    Advanced
                  </h3>
                  <h4 className='display-6 fw-semi-bold'>
                    &#8377; 20,000<span>/year</span>
                  </h4>
                </div>
                <div className='pricing-info mb-4'>
                  <ul className='pricing-feature-list list-unstyled'>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      Student Management
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      Invoicing
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      Payment Reminder
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      SMS/WhatsApp Notification
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      Digital Payments
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      Dynamic QR
                    </li>
                  </ul>
                </div>
                <Link
                  to='/'
                  className='btn btn-primary mt-auto width-fit-content'
                >
                  Buy Now
                </Link>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 card-group'>
              <div
                className='card
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-white
                  custom-shadow
                  p-5
                  mb-4 mb-lg-0
                '
              >
                <div className='pricing-header mb-32'>
                  <h3 className='package-name text-primary d-block'>Premium</h3>
                  <h4 className='display-6 fw-semi-bold'>
                    &#8377; 50,000<span>/year</span>
                  </h4>
                </div>
                <div className='pricing-info mb-4'>
                  <ul className='pricing-feature-list list-unstyled'>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Student Management
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Invoicing
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Payment Reminder
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      SMS/WhatsApp/Voice Calls Notification
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Digital Payments
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Dynamic QR
                    </li>
                  </ul>
                </div>
                <Link
                  to='/'
                  className='btn btn-outline-primary mt-auto width-fit-content'
                >
                  Buy Now
                </Link>

                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 top--40'>
                  <img src='assets/images/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
