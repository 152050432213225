import React from 'react';
import { Layout } from './components/Layout';
import { HeaderSection } from './components/HeaderSection';
import { IntroSection } from './components/IntroSection';
import { Promo } from './components/Promo';
import { FeaturesSection } from './components/FeaturesSection';
import { PriceSection } from './components/PriceSection';
import { ClientTestimonials } from './components/ClientTestimonials';
import { ScheduleDemoSection } from './components/ScheduleDemoSection';
import { DeveloperHelpSection } from './components/DeveloperHelpSection';
import { FAQSection } from './components/FAQSection';
import { FooterSection } from './components/FooterSection';

const MainContainer = () => {
  return (
    <Layout>
      <HeaderSection classOption='navbar-light' />
      <IntroSection />
      <Promo />
      <FeaturesSection />
      <PriceSection haspriceTitleWithHeader paddingTop='pt-60 pb-120' />
      <ClientTestimonials />
      <ScheduleDemoSection />
      <DeveloperHelpSection />
      <FAQSection />
      <FooterSection
        style={{
          background:
            "url('assets/images/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default MainContainer;
