import React from 'react'

export const ContactUsSection = () => {
  return (
    <>
      <section className='contact-promo ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>

          <div className='col-lg-12 col-md-6 mt-4 mt-lg-0'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fad fa-address-card fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Merchant Legal Entity Name</h5>
                  <p>GOORBIT TECHNOLOGIES PRIVATE LIMITED</p>
                  <h5>Corporate Identity Number</h5>
                  <p>U62020UP2023PTC182002</p>
                 
                </div>
                
              </div>
            </div>
            
            <div className='col-lg-6 col-md-6 mt-4 mt-lg-5'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fad fa-home fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Registered Address</h5>
                  <p>GOORBIT TECHNOLOGIES PRIVATE LIMITED
                    1810/C 17th FLOOR TOWER C, SECTOR-79,GAUR SPORTSWOOD, NOIDA{' '} </p>
                  <p><strong>Gautam Buddha Nagar</strong></p>
                  <p><strong>UTTAR PRADESH 201301</strong></p> 
                 
                </div>
                
              </div>
            </div>

            <div className='col-lg-6 col-md-6 mt-4 mt-lg-5'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fad fa-building fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Corporate Address</h5>
                  <p>GOORBIT TECHNOLOGIES PRIVATE LIMITED,
                  G12, IHDP Business Park, Plot, Street Number 7, Sector 127, Noida{' '} </p>
                  <p><strong>Gautam Buddha Nagar</strong></p>
                  <p><strong>UTTAR PRADESH 201304</strong></p> 
                 
                </div>
                
              </div>
            </div>
            
            <div className='col-lg-6 col-md-6 mt-4 mt-lg-6'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fad fa-envelope fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Email Us</h5>
                  <p>
                    Drop us an email at{' E-Mail ID '}
                    <strong>rajat.upadhyay@goorbit.in </strong> 
                    and you'll receive a reply within 24 hours
                  </p>
                </div>
                <a
                  href='mailto:hellothemetags@gmail.com'
                  className='btn btn-primary mt-auto'
                >
                  Email Us
                </a>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 mt-4 mt-lg-6'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fad fa-phone fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Give us a call</h5>
                  <p>
                    Give us a ring.Our Experts are standing by{' '}
                    <strong>monday to friday</strong> from
                    <strong>9 AM to 5 PM IST.</strong>
                  </p>
                </div>
                <a href='tel:+91-8527048080' className='btn btn-link mt-auto'>
                +91-8527048080
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
