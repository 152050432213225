import React from 'react'
import { Link } from 'react-router-dom'

export const NotFoundScreen = () => {
  return (
    <>
      <section
        className='error-section ptb-120 bg-dark min-vh-100 w-100 d-flex flex-column justify-content-center'
        style={{
          background:
            "url('assets/images/page-header-bg.svg')no-repeat bottom right",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-md-6'>
              <div className='error-page-content-wrap'>
                <h2 className='error-404 text-warning'>Sorry!</h2>
                <h1 className='display-6 fw-bold'>
                  We couldn't find this page
                </h1>
                <p className='lead'>
                  Try searching or go to{' '}
                  <a href='https://www.billorbit.com'>www.billorbit.com</a>
                </p>
                <Link to='/' className='btn btn-primary mt-4'>
                  Go Back Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
