import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

export const ClientTestimonials = () => {
  const swiperOption = {
    slidesPerView: 1,
    loop: true,
    speed: 700,
    pagination: {
      clickable: true,
      el: '.eve-pagination.swiper-pagination',
    },
    autoplay: {
      delay: 2000,
    },
  };
  return (
    <section
      id='testimonials-section'
      className='ev-testimonial bg-light-blue ptb-120'
    >
      <ul className='list-unstyled author d-none d-xl-block'>
        <li>
          <img
            src='assets/images/tbo-1.png'
            className='img-fluid'
            alt='icon'
          />
        </li>
        <li>
          <img
            src='assets/images/tbo-2.png'
            className='img-fluid'
            alt='icon'
          />
        </li>
      </ul>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='text-center mb-4'>
              <span className='fw-bold text-dark-blue'>Testimonial</span>
              <h2>Hear What Our Customers Have To Say</h2>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <Swiper
              className='ev-testimonial-slider'
              {...swiperOption}
              modules={[Pagination]}
            >
              <SwiperSlide>
                {' '}
                <div className='text-center ev-single-testimonial'>
                  <img
                    src='assets/images/GS.png'
                    alt='avatar'
                    className='mb-3'
                  />
                  <h5>Gaurav Gupta</h5>
                  <ul className='list-unstyled rating-list list-inline mb-0'>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                  </ul>
                  <p>
                    We have been using BillOrbit for our school for the past
                    year, and it has been a game-changer. With this system, We
                    no longer have to worry about manually tracking and
                    invoicing student's fees every month. It's automated,
                    efficient, and has saved me countless hours of work and
                    reduced our defaults. The system is also highly
                    customizable, allowing me to tailor course fees and
                    discounts. Overall, I highly recommend BillOrbit for any
                    school looking to streamline their billing process and
                    improve their customer experience.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {' '}
                <div className='text-center ev-single-testimonial'>
                  <img
                    src='assets/images/DJ2.png'
                    alt='avatar'
                    className='mb-3'
                  />
                  <h5>Diksha Jain</h5>
                  <ul className='list-unstyled rating-list list-inline mb-0'>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                  </ul>
                  <p>
                    Billorbit is incredibly user-friendly and easy to set up and
                    use, which allowed us to start billing our students in no
                    time. The best part is the flexibility it offers - We can
                    customize our courses and fees to suit our business needs,
                    which has helped us roll out the changes very quickly. I
                    highly recommend the Billorbit billing system for any
                    business looking to streamline their revenues and improve
                    their bottom line.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {' '}
                <div className='text-center ev-single-testimonial'>
                  <img
                    src='assets/images/SS.png'
                    alt='avatar'
                    className='mb-3'
                  />
                  <h5>Sumit Sharma</h5>
                  <ul className='list-unstyled rating-list list-inline mb-0'>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                    <li className='list-inline-item'>
                      <i className='fas fa-star text-warning'></i>
                    </li>
                  </ul>
                  <p>
                    As a School owner, I was always struggling with
                    managing my monthly revenue until I came across
                    BillOrbit. This software has completely revolutionized the
                    way We manage my collections for our school
                    students. The intuitive interface and robust functionality
                    make it easy to set up and manage fee cycles, manage
                    Student information and track revenue. The system is highly
                    customizable and flexible, making it easy to adapt to the
                    specific needs of my School. I am grateful for the
                    benefits BillOrbit has brought to our organization.
                  </p>
                </div>
              </SwiperSlide>
              <div className='eve-pagination swiper-pagination'></div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};
