import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OffCanvasMenu } from './OffCanvasMenu';
import { Modal } from './Modal/Modal';

const redirectURI = process.env.REACT_APP_LOGIN_API_END_POINT;

export const HeaderSection = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [show, setShow] = useState(false);
  const [tanentName, setTanentName] = useState('');

  const redirectToLogin = () => {
    setShow(false);
    window.open(`https://${tanentName}${redirectURI}`, '_blank').focus();
  };

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setHeaderTop]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const smoothScroll = (ele) => {
    ele.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const ScrollToHome = () => {
    let home = document.getElementById('home');
    smoothScroll(home);
  };

  const ScrollToFeatures = () => {
    let features = document.getElementById('features-section');
    smoothScroll(features);
  };
  const ScrollToPricing = () => {
    let pricing = document.getElementById('pricing-section');
    smoothScroll(pricing);
  };

  const ScrollToTestimonials = () => {
    let testimonials = document.getElementById('testimonials-section');
    smoothScroll(testimonials);
  };

  const ScrollToFAQs = () => {
    let faqs = document.getElementById('FAQs-section');
    smoothScroll(faqs);
  };

  const ScrollToDemoSection = () => {
    let faqs = document.getElementById('request-demo-section');
    smoothScroll(faqs);
  };

  return (
    <>
      <header
        id='home'
        className={`main-header ${
          navDark ? 'position-absolute ' : ''
        } w-100 position-absolute `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? 'navbar-dark' : 'navbar-light'
          } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className='container d-flex align-items-center justify-content-lg-between position-relative'>
            <Link
              to='/'
              className='navbar-brand d-flex align-items-center mb-md-0 text-decoration-none bill-orbit-logo'
            >
              <img
                src='assets/images/logo-bo-white.png'
                alt='logo'
                className='img-fluid logo-white'
              />
              <img
                src='assets/images/logo-bo-color.png'
                alt='logo'
                className='img-fluid logo-color'
              />
            </Link>
            <Link
              className='navbar-toggler position-absolute right-0 border-0'
              to='#offcanvasWithBackdrop'
              role='button'
            >
              <span
                className='far fa-bars'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasWithBackdrop'
                aria-controls='offcanvasWithBackdrop'
              ></span>
            </Link>

            <div className='collapse navbar-collapse justify-content-center'>
              <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
                <li>
                  <Link to='/' className='nav-link' onClick={ScrollToHome}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link' onClick={ScrollToFeatures}>
                    Features
                  </Link>
                </li>
                {/* <li className='nav-item dropdown'>
                  <Link
                    className='nav-link dropdown-toggle'
                    to='/'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    Solution
                  </Link>
                  <div className='dropdown-menu border-0 rounded-custom shadow py-0 bg-white'>
                    <div className='dropdown-grid rounded-custom width-half'>
                      <div className='dropdown-grid-item'>
                        <Link to='/' className='dropdown-link'>
                          <span className='demo-list bg-primary rounded text-white fw-bold'>
                            1
                          </span>
                          <div className='dropdown-info'>
                            <div className='drop-title'>GYM</div>
                            <p>
                              It's for <strong>GYMs</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to='/' className='dropdown-link'>
                          <span className='demo-list bg-primary rounded text-white fw-bold'>
                            2
                          </span>
                          <div className='dropdown-info'>
                            <div className='drop-title'>School</div>
                            <p>
                              Modern <strong>Schools</strong>
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li> */}
                <li>
                  <Link to='/' className='nav-link' onClick={ScrollToPricing}>
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to='/'
                    className='nav-link'
                    onClick={ScrollToTestimonials}
                  >
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link
                    to='/'
                    className='nav-link'
                    onClick={ScrollToDemoSection}
                  >
                    Schedule Free Demo
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <Link
                    className='nav-link dropdown-toggle'
                    to='/'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    Resources
                  </Link>
                  <div className='dropdown-menu border-0 rounded-custom shadow py-0 bg-white'>
                    <div className='dropdown-grid rounded-custom width-half'>
                      <div className='dropdown-grid-item'>
                        <Link to='/' className='dropdown-link'>
                          <span className='demo-list bg-primary rounded text-white fw-bold'>
                            1
                          </span>
                          <div className='dropdown-info'>
                            <div className='drop-title'>Support</div>
                          </div>
                        </Link>
                        {/* <Link to='/' className='dropdown-link'>
                          <span className='demo-list bg-primary rounded text-white fw-bold'>
                            2
                          </span>
                          <div className='dropdown-info'>
                            <div className='drop-title'>Help</div>
                          </div>
                        </Link> */}
                        <Link to='/' className='dropdown-link'>
                          <span className='demo-list bg-primary rounded text-white fw-bold'>
                            2
                          </span>
                          <div className='dropdown-info'>
                            <div className='drop-title'>Blog</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to='/' className='nav-link' onClick={ScrollToFAQs}>
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>

            <div className='action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block'>
              <button onClick={() => setShow(true)} className='btn btn-primary'>
                Login
              </button>
              <Modal
                title='Tanent Login'
                onClose={() => setShow(false)}
                onLoginClick={() => redirectToLogin()}
                show={show}
              >
                <div className='col'>
                  <label htmlFor='phone' className='mb-1'>
                    Tanent Name
                  </label>
                  <div className='input-group mb-3'>
                    <input
                      onChange={(e) => setTanentName(e.target.value)}
                      type='text'
                      value={tanentName}
                      className='form-control'
                      placeholder='Tanent Name'
                      aria-label='Tanent Name'
                    />
                  </div>
                </div>
              </Modal>
            </div>
            <OffCanvasMenu
              setShow={setShow}
              show={show}
              redirectToLogin={redirectToLogin}
              setTanentName={setTanentName}
              tanentName={tanentName}
            />
          </div>
        </nav>
      </header>
    </>
  );
};
