import React from 'react';
import { SectionTitle } from './SectionTitle';

export const Promo = ({ bgWhite }) => {
  return (
    <>
      <section
        className={`promo-section ptb-120 ${
          bgWhite ? 'bg-white' : 'bg-light'
        } `}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <SectionTitle
                title='Trusted by More Than 75+ Schools & 500+ Users Globally'
                description='Bill Orbit is the fastest way for your School Or Institute to track, manage and charge Student with tution and other fees. Bill Orbit is highly flexible and helps to capture more revenue, support introducing new fees for any business models, and accept payments globally.'
                centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-fingerprint text-warning me-2'></i> 10
                  </h3>
                  <p className='text-white'>Avg. Client Onboarding per Month</p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-paper-plane text-warning me-2'></i> 5X
                  </h3>
                  <p className='text-white'>Better Experience & Flexible</p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
                data-aos='fade-up'
                data-aos-delay='200'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-chart-pie-alt text-warning me-2'></i>
                    98%
                  </h3>
                  <p className='text-white'>Client Rated us 4+ stars on 5</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
