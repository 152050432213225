const FaqOneData = [
  {
    id: 1,
    faqTitle: 'How much time it takes to onboard on BillOrbit Platform?',
    faqDesc:
      'Typically, it takes around a day for us to onboard you onto the BillOrbit platform if your data is complete and ready. However, we have found that Schools may require up to a week to prepare their Student and their Fee data before beginning the onboarding process.',
  },
  {
    id: 2,
    faqTitle: 'Do we need to pay upfront prices for using the BillOrbit Platform?',
    faqDesc:
      'NO - At BillOrbit, we do not require any upfront payment to use our platform. You are free to use our platform for a year before deciding whether to pay. We provide the satisfaction guarantee by stating that if you are satisfied with our services, you can pay at the end of the year.',
  },
  {
    id: 3,
    faqTitle: 'Is it possible to obtain a copy of our data if we decide to stop using the BillOrbit platform?',
    faqDesc:
      'At BillOrbit, we understand the importance of your data. If you decide to separate from our platform, we will provide you with a dump of your data upon request. Additionally, you can access and download all of your data throughout your entire journey with us.',
  },
];

export { FaqOneData };
