import React from 'react'
import { Layout } from './components/Layout'
import { HeaderSection } from './components/HeaderSection'
import { PrivacyPolicySection } from './components/PrivacyPolicySection'

import PrivacyPolicyDetails from './components/PrivacyPolicyDetails';
import { FooterSection } from './components/FooterSection'

const ContactUsContainer = () => {
  return (
    <Layout>
      <HeaderSection navDark/>

      <PrivacyPolicySection />

      <PrivacyPolicyDetails />

      <FooterSection
        style={{
          background:
            "url('assets/images/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
    
  )
}

export default ContactUsContainer
