import React from 'react';
import { SectionTitle } from './SectionTitle';

export const DeveloperHelpSection = () => {
  return (
    <>
      <section className='cta-subscribe bg-dark ptb-120 position-relative overflow-hidden'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='subscribe-info-wrap text-center position-relative z-2'>
                <SectionTitle
                  subtitle='For Developers'
                  title='Building blocks for School Management'
                  description='The Bill Orbit API is easy to integrate with existing websites, mobile apps, and ERP systems. Developers can use our composable API to get started quickly or design customized Solution.'
                  dark
                />
              </div>
            </div>
          </div>
          <div
            className='
              bg-circle
              rounded-circle
              circle-shape-3
              position-absolute
              bg-dark-light
              left-5
            '
          ></div>
          <div
            className='
              bg-circle
              rounded-circle
              circle-shape-1
              position-absolute
              bg-warning
              right-5
            '
          ></div>
        </div>
      </section>
    </>
  );
};
