
import React from 'react';

export const PrivacyPolicySection = () => {
  return (
    <section
      className='hero-section text-white hero-ten'
      style={{
        background:
          "url('assets/img/cyber_banner_img.png')no-repeat center center",
      }}
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-10'>
            <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
              <h1 className='fw-bold display-5 text-white'>
                Privacy Policy
              </h1>
              <p className='lead'>Last updated on Sep 20th 2023</p>
              
              <p className='lead'>
              This privacy policy sets out how GOORBIT TECHNOLOGIES PRIVATE LIMITED uses and protects any information that you give GOORBIT TECHNOLOGIES PRIVATE LIMITED when you use this or any of the company website.
              </p>
              <div className='action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex'>
                
                
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-8 mt-5'>
            <div className='hero-img position-relative circle-shape-images'>
              <img
                src='assets/img/cyber_banner_image.svg'
                alt='hero img'
                className='img-fluid position-relative z-5'
              />
            </div>
          </div>
        </div>
        <div className='cyber-down-btn text-center position-relative d-none d-lg-block'>
          <a href='#cyber-about' className='text-primary'>
            <i className='far fa-arrow-down'></i>
          </a>
        </div>
      </div>
    </section>
  );
};

