import React from 'react';
import { SectionTitle } from './SectionTitle';

export const FeaturesSection = () => {
  return (
    <section id='features-section' className='features-section ptb-120'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8 col-md-10'>
            <SectionTitle
              subtitle='Features'
              variant='text-primary'
              title='One-stop Automated School Fee Management System'
              description='Collect School Fee Conveniently'
              centerAlign
            />
          </div>
        </div>
        <div className='d-flex align-items-start features-tabs'>
          <ul
            className='flex-column nav nav-pills testimonial-tab-menu'
            id='testimonial'
            role='tablist'
          >
            <li className='nav-item w-100' role='presentation'>
              <div
                className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-1'
                role='tab'
                aria-selected='false'
              >
                <div className='author-info'>
                  <h6 className='mb-0'>Fee Management</h6>
                </div>
              </div>
            </li>
            <li className='nav-item  w-100' role='presentation'>
              <div
                className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-2'
                role='tab'
                aria-selected='false'
              >
                <div className='author-info'>
                  <h6 className='mb-0'>Student Management</h6>
                </div>
              </div>
            </li>
            <li className='nav-item  w-100' role='presentation'>
              <div
                className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-3'
                role='tab'
                aria-selected='false'
              >
                <div className='author-info'>
                  <h6 className='mb-0'>Class/Grade Management</h6>
                </div>
              </div>
            </li>
            <li className='nav-item  w-100' role='presentation'>
              <div
                className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-4'
                role='tab'
                aria-selected='false'
              >
                <div className='author-info'>
                  <h6 className='mb-0'>Invoicing and Digital Payments</h6>
                </div>
              </div>
            </li>
          </ul>
          <div className='row'>
            <div className='ms-5 ms-5-sm'>
              <div className='tab-content' id='testimonial-tabContent'>
                <div
                  className='tab-pane fade active show'
                  id='testimonial-tab-1'
                  role='tabpanel'
                >
                  <ul className='list-unstyled d-flex flex-column mt-4 mb-4'>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Allow to create Flexible Fee Structure
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Introduce New Fees By Rolling Out Configuration Changes
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Support Proration
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Instant Bus Route Addition
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Change Price Change Instantly
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Integration With ERP
                    </li>
                  </ul>
                </div>
                <div
                  className='tab-pane fade'
                  id='testimonial-tab-2'
                  role='tabpanel'
                >
                  <ul className='list-unstyled d-flex flex-column mt-4 mb-4'>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Student Identity Resolution - Student Deduplication
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Student Profile - Create a Student profile, segmentation and credit reating
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Student Data Governance and Security
                    </li>
                  </ul>
                </div>
                <div
                  className='tab-pane fade'
                  id='testimonial-tab-3'
                  role='tabpanel'
                >
                  <ul className='list-unstyled d-flex flex-column mt-4 mb-4'>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Prorate and bill automatically Thus No more hassle over
                      manual calculations for class upgrades or
                      downgrades. Bill Orbit automates proration
                      and bills students based on their prorated cycles they remain in a class/grade.
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Its Possible to Manage Students class/grade
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Class/Grade Can be Upgraded Or Downgraded
                    </li>
                  </ul>
                </div>
                <div
                  className='tab-pane fade'
                  id='testimonial-tab-4'
                  role='tabpanel'
                >
                  <ul className='list-unstyled d-flex flex-column mt-4 mb-4'>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Auto-bill your Student - Set up fee frequencies, and
                      cycles for your school or institute, and then relax. Bill
                      Orbit System will automate your collection
                      and based on the billing cycle sends out
                      professional-looking invoices before due dates.
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Personalize invoices - Personalize invoices to showcase
                      your institute identity. With multiple customizable invoice
                      templates, you can modify them to suit your exact needs.
                      You can add your scholl's logo, set a background image
                      and customize the template. You can also capture more
                      information with the help of custom fields.
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Secure Multiple Payment Options - Bill Orbit
                      is partered with compliant payment platform. So
                      you can rest assured that your Student' preferred
                      payment option information is handled in a safe and secure
                      manner.
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle me-2 text-primary'></i>
                      Payments Reminder and Notification
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
