import React from 'react'

export const ShippingSection = () => {
  return (
    <>
      <section className='blog-details ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='blog-details-wrap'>

                <div className='blog-details-info mt-5'>
                  <h3 className='h5'>Shipping is not applicable for business.</h3>
                  
                  <blockquote className='bg-white custom-shadow p-5 mt-5 rounded-custom border-4 border-primary border-top'>
                    <p className='text-muted'>
                      <i className='fas fa-quote-left me-2 text-primary'></i>{' '}
                      We do not ship or deliver any product, We provide software products and service online.
                      <i className='fas fa-quote-right ms-2 text-primary'></i>
                    </p>
                  </blockquote>
                </div>

              </div>
            </div>
            <div className='col-lg-4'>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};